<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Latest GB MOD APPS 2025
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img" />
                  <div class="mod-item-content">
                    <h2 class="mod-item-title"> <a href="https://gbwhatapks.com/downloadpage/" target="_blank"
                        class="jump-url">GBWhatsApp</a></h2>
                    <!-- <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      Download
                    </a> -->
                    <button class="mod-item-btn" @click="download">Download</button>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img" />
                  <div class="mod-item-content">
                    <h2 class="mod-item-title"> <a :href="item.link" target="_blank" class="jump-url">{{ item.title
                        }}</a></h2>
                    <button class="mod-item-btn" @click="download">Download</button>
                  </div>
                </div>
              </div>
              <h2>What Are WhatsApp Mod APKs?</h2>
              <p>
                WhatsApp's official app updates often lack the features users want. To fill this gap, different
                developers created WhatsApp mods like GB WhatsApp mod apk, FM WhatsApp mod apk, and Yo WhatsApp, packed
                with extra functionalities.
              </p>
              <p>
                On this page, you'll find detailed information about WhatsApp Messenger Mod APK. We provide safe
                download links, step-by-step guides, and feature introductions to help you explore and enjoy these
                modified apps.
              </p>
              <p>
                Feel free to download WhatsApp Mod APK latest version you like now!
              </p>
              <h2>WhatsApp Mod App Features</h2>
              <p>
                WhatsApp mod apps bring a whole new level of customization and functionality, making them a favorite among users who want more than what the official app offers. These mods come packed with unique features that can be grouped into three main categories:
              </p>
              <h3>
                Advanced Privacy Controls
              </h3>
              <p>
                Stay in full control of your online presence! Hide your last seen, online status, blue ticks, and even typing indicators. Some mods also let you view deleted messages and statuses without the sender knowing.
              </p>
              <h3>
                Customization & Themes
              </h3>
              <p>
                Tired of WhatsApp's default green theme? With mod apps, you can choose from thousands of themes, change fonts, and personalize chat backgrounds to match your style.
              </p>
              <h3>
                Messaging & Media Enhancements
              </h3>
              <p>
                Send high-quality images and videos without compression, share files larger than 2GB, and use auto-reply and scheduled messaging for added convenience. Plus, enjoy features like DND mode, which lets you disable the internet for WhatsApp while keeping the rest of your phone connected.
              </p>
              <p>
                Go to our individual pages to explore all the powerful features!
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Privacy Policy</a>
            |
            <a href="/contact-us/">Contact Us</a>
            |
            <a href="/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link
                :to="{ name: `${selectedLanguage}-gb` }">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>


    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';


export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      selectedLanguage: 'en',
      doc: doc,
      apk: null,
    };
  },
  mounted() {
    this.downloadLink();
  },
  methods: {
    gotodownload() {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    downloadLink() {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>