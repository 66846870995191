import { link } from 'fs';

export default {
  modList:[
    {
      title: 'FM WhatsApp',
      dlpg_title: 'Fouad WhatsApp',
      img: require('@/assets/blog6-logo-4.webp'),
      name: 'fm',
      link: 'https://gbwhatapks.com/fm-whatsapp-new/',
    },
    {
      title: 'GBWhatsApp iOS',
      img: require('@/assets/ios.webp'),
      name: 'ios',
      link: 'https://gbwhatapks.com/gbwhatsapp-ios/',
    },
    {
      title: 'YO WhatsApp',
      img: require('@/assets/blog6-logo-7.webp'),
      name: 'yo',
      link: 'https://gbwhatapks.com/yowhatsapp-download/',
    },
    {
      title: 'WhatsApp Plus',
      img: require('@/assets/blog6-logo-3.webp'),
      name: 'plus',
      link: 'https://gbwhatapks.com/whatsapp-plus/',
    },
    {
      title: 'OB WhatsApp',
      img: require('@/assets/blog6-logo-6.webp'),
      name: 'ob',
      link: 'https://gbwhatapks.com/ob-whatsapp/',
    },
    {
      title: 'GB WhatsApp Pro',
      img: require('@/assets/blog6-logo-2.webp'),
      name: 'pro',
      link: 'https://gbwhatapks.com/gbwhatsapp-pro/',
    }
  ]
}